
import { defineComponent, ref, computed } from 'vue';
import { useEditUserForm, useCreateUserForm } from '@/composables/useUserForm';
import { useAuth } from '@/composables/useAuth';
import { useOrgManager } from '@/composables/useOrgManager';
import router from '@/router';
import { FetchStates } from '@/helpers/fetch-states';

export default defineComponent({
  name: 'CreateUserPage',
  setup() {
    const isEditing = computed(() =>
      router.currentRoute.value.path.includes('/users/edit'),
    );

    const { isVAR } = useAuth();
    const { orgs, activeOrgs } = useOrgManager();
    const defaultOrgId = isVAR.value
      ? orgs.value[0].id
      : activeOrgs.value[0].id;

    const formRef = ref(null);
    const useForm = useCreateUserForm(formRef, defaultOrgId);

    const routeToUsers = () => router.push({ name: 'UsersPage' });

    return {
      ...useForm,
      formRef,
      isEditing,
      isVAR,
      orgs,
      routeToUsers,
      FetchStates,
    };
  },
});
